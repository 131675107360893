<!-- src/views/AboutView.vue -->
<template>
<div>

    <div class="container-fluid about-bac">
        <NavBar :activeTab="activeTab" />
        <!-- <img src="../assets/about/image@2x.png" alt="关于紫江" /> -->
    </div>
    <div class="container-fluid fixed-top-add">
        <div class="container about-top ">
            <div class="about-title">
                <div class="">
                    <div class="breadCrumb-all">
                        <div class="container breadCrumb-a">
                            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item hover-div" @click="goToPage('')">{{ $t('home') }}</li>
                                    <!-- <li class="breadcrumb-item" @click="goToPage('AboutView')">{{ $t('about') }}</li> -->
                                    <!-- <li class="breadcrumb-item" aria-current="page"> {{ currentLanguage === 'zh' ? DataView[0].title_cn : DataView[0].title_en }}</li> -->
                                    <li class="breadcrumb-item" aria-current="page"> {{ $t('guanyu') }}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="about-nav">
                    <ul class="nav" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ active: activeTabAbout === 'home' }" id="home-tab" href="#home" @click="selectTab('home')">
                                {{ $t('jianjie') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[0].title_cn : DataView[0].title_en }} -->
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ active: activeTabAbout === 'profile' }" id="profile-tab" href="#profile" @click="selectTab('profile')">
                                {{ $t('jiazhi') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[1].title_cn : DataView[1].title_en }} -->
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ active: activeTabAbout === 'contact' }" id="contact-tab" href="#contact" @click="selectTab('contact')">
                                {{ $t('licheng') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[2].title_cn : DataView[2].title_en }} -->
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ active: activeTabAbout === 'zr' }" id="zr-tab" href="#zr" @click="selectTab('zr')">
                                {{ $t('shehui') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[3].title_cn : DataView[3].title_en }} -->
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container" id="home">
            <div class="AppLayoutView">
                <div class="AppLayout-title">
                    {{ currentLanguage === 'zh' ? DataView[0].title_cn : DataView[0].title_en }}
                </div>
                <div class="PressCenterDetails-content AppLayout-View" v-html="currentLanguage === 'zh' ? DataView[0].content_cn : DataView[0].content_en"></div>
                <!-- <p>
                        紫江，创始于1981年，是一家根植于上海的民营投资控股集团。<br /><br />
                        紫江之名，取自发源地附近一条“紫港”的江流，也寓意紫江将不断汇聚力量，迈向更广阔的发展空间。<br /><br />
                        紫江创始人沈雯，现任上海紫江（集团）有限公司董事长、总裁，上海紫江企业集团股份有限公司董事长，上海紫竹高新区（集团）有限公司董事长、总经理，是第十届、十一届、十二届全国政协委员。<br /><br />
                        紫江从包装业起家，目前投资领域主要以制造业和高新区为主。制造业领域，以包装业务为核心的上海紫江企业集团股份有限公司于1999年上市；高新区领域，主要从事紫竹国家高新技术产业开发区、房地产及酒店等的开发与管理。作为上海最早成立党组织的民营企业之一，紫江成立40多年来，以“产业报国、科教兴国”为己任，并积极投身教育事业和科创领域，致力于为区域繁荣发展贡献已力。<br /><br />
                    </p> -->
            </div>
        </div>
        <div class="aboutBacA" id="profile">
            <div class="container faz">
                <h1 style="text-align: center;"> {{ currentLanguage === 'zh' ? DataView[1].title_cn : DataView[1].title_en }}</h1>
                <div class="line"></div>
                <p class="about-view" v-html="currentLanguage === 'zh' ? DataView[1].content_cn : DataView[1].content_en"></p>
                <div class="row about-all">
                    <div class="col-md-4" v-for="(data, index) in (currentLanguage === 'zh' ? DataView[1].carousel_cn : DataView[1].carousel_en)" :key="index">
                        <img class="about-1" :src="$imgUrl + data.image" alt="" />
                        <div class="about-view-title"> {{ data.title }}</div>
                        <div class="about-view-detaills"> {{ data.content }}</div>
                    </div>
                    <!-- <div class="col-md-4">
                        <img class="about-2" src="@/assets/about/about-2.png" alt="" />
                        <div class="about-view-title">价值观</div>
                        <div class="about-view-detaills">专注、简单、持久、执著</div>
                    </div>
                    <div class="col-md-4">
                        <img class="about-3" src="@/assets/about/about-3.png" alt="" />
                        <div class="about-view-title">座右铭</div>
                        <div class="about-view-detaills">清清白白做人，兢兢业业做事</div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="container historyAll">
            <div class="row">
                <div class="col-md-6 history-all hover-div" id="contact" @click="goToPage('History')">
                    <div class="history-all-left-1">
                        <div class="history-po">
                            <div class="history-title">HISTORY</div>
                            <div class="history-view">发展历程</div>
                            <div class="history-tag"></div>
                            <div class="history-detaills">从创立至今，紫江已走过四十年的创业历程。</div>
                            <div class="history-more">
                                了解更多
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 history-all hover-div" id="zr" @click="goToPage('ESG')">
                    <div class="history-all-right-2">
                        <div class="history-po-right">
                            <div class="history-title">ESG</div>
                            <div class="history-view">社会责任</div>
                            <div class="history-tag historyadd"></div>
                            <div class="history-detaills">长期以来，紫江一直以“产业报国、科教兴国”为己任，<br />重视经济效益和社会效益的协同发展。</div>
                            <div class="history-more historyadd">
                                了解更多
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <FooterBar />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
    name: 'AboutView',
    components: {
        NavBar,
        FooterBar,
    },
    data() {
        return {
            activeTabAbout: 'home',
            activeTab: 'about',
            currentLanguage: this.$i18n.locale,
            DataView: ''
        };
    },
    created() {
        console.log(this.currentLanguage)
        this.getPage(4)
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {
        selectTab(tabId) {
            console.log(tabId);
            this.activeTabAbout = tabId;
        },
        goToPage(page) {
            console.log(page);
            window.open("/" + page);
        },
        //获取页面详细
        async getPage(typeInt) {
            const params = {
                type: typeInt // 使用传入的 typeInt 参数
            };
            try {
                const response = await this.$api.get('/api/page', {
                    params
                });
                console.log(response.data);
                this.DataView = response.data.row;
                console.log(this.DataView)
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                // 可以在这里添加任何需要在请求完成后执行的逻辑
            }
        }
    }
};
</script>

<style scoped>
.history-title {
    font-size: 50px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    color: #FFFFFF;
    opacity: 0.2;
}

.container-fluid {
    padding: 0;
}

.history-all-right-2 {
    position: relative;
    height: 350px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.history-all-right-2::before {
    content: "";
    background: linear-gradient(243deg, #5266A5 -1%, rgba(82, 102, 165, 0.44) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('@/assets/about/2.png') no-repeat center center;
    background-size: cover;
    transition: transform 0.5s ease-in-out;
    z-index: 1;
    border-radius: 10px;
}

.history-all-right-2:hover::before {
    transform: scale(1.1);
}

.history-all-left-1 {
    position: relative;
    height: 350px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.history-all-left-1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(@/assets/about/image@2-5.png) no-repeat center center;
    background-size: cover;
    transition: transform 0.5s ease-in-out;
    z-index: 1;
    border-radius: 10px;
}

.history-all-left-1:hover::before {
    transform: scale(1.1);
}

.about-bac {
    background: url(@/assets/about/image@2x.png) 0px 0px / contain no-repeat fixed;
    height: 290px;
    position: relative;
}

.about-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8291C1;
}

.active {
    border-bottom: 3px solid #8291C1;
    padding-bottom: 12px;
}

.nav-item a {
    text-decoration: none;
    font-size: 16px;
    margin: 0 30px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D;
}

.AppLayout-title {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    margin-top: 25px;
    display: inline-block;
    letter-spacing: 0em;
    border-bottom: 6px solid #8291C1;
}

.AppLayout-View {
    padding: 20px 0;

}

.faz {
    padding: 60px 0 30px;
}

.line {
    margin: 30px auto;
    width: 100px;
    height: 0px;
    opacity: 1;
    border-top: 6px solid #8291C1;
}

.about-view {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0em;
}

.about-view-title {
    color: #5266A5;
    padding: 20px 0 5px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
}

.about-view-detaills {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: #3D3D3D;
}

.about-1 {
    width: 86px;
    height: 77px;
}

.about-2 {
    width: 82px;
    height: 70px;
}

.about-3 {
    width: 70px;
    height: 77px;
}

.about-all {
    text-align: center;
    padding: 30px 0;
    align-items: flex-end;
}

.history {
    width: 100%;
}

.history-view {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-detaills {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-tag {
    width: 30px;
    height: 10px;
    background-color: #33A37A;
    margin: 10px 0 30px;
}

.history-more {
    width: 150px;
    height: 32px;
    border-radius: 33px;
    opacity: 1;
    margin-top: 30px;
    line-height: 30px;
    text-align: center;
    background: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.history-all {
    position: relative;
}

.history-po {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
}

.history-po-right {
    position: absolute;
    top: 50%;
    right: 60px;
    text-align: right;
    transform: translateY(-50%);
}

.historyadd {
    margin-left: auto;
}

.historyAll {
    padding: 60px 0 18px;
}

.aboutBacA {
    background-color: #F5F5F5;
}

html {
    scroll-behavior: smooth;
}

#home {
    padding: 30px 0;
    background-image: url('@/assets/earthBacB.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}
</style>
