<template>
<div>

    <div class="container-fluid about-bac">
        <NavBar :activeTab="activeTab" />
        <!-- <img src="../assets/about/image@2x.png" alt="关于紫江" /> -->
    </div>
    <div class="container-fluid fixed-top-add">
        <div class="breadCrumb-all">
            <div class="container breadCrumb-a">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                         <li class="breadcrumb-item hover-div" @click="goToPage('')">{{ $t('home') }}</li>
                        <li class="breadcrumb-item" aria-current="page">新闻中心</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="centerH1">
            <div class="row align-items-start">
                <div class="col-md-4 hover-div" @click="handleClickTop(index)" v-for="(data, index) in dataTop" :key="index">
                    <figure class="figure">
                        <img :src="$imgUrl + (currentLanguage === 'zh' ? data.list_img_cn : data.list_img_en)" class="figure-img img-fluid rounded listimg" alt="...">
                        <figcaption class="text-start start-title start-viewpad">
                            {{ currentLanguage === 'zh' ? data.title_cn : data.title_en }}
                        </figcaption>
                        <p class="start-view start-viewpad">
                            {{ currentLanguage === 'zh' ? data.description_cn : data.description_en }}
                        </p>
                        <p class="text-start text-time start-viewpadb">{{ data.release_time }}</p>
                    </figure>
                </div>
                <!-- <div class="col-md-4" @click="goToPage('PressCenterDetails')">
            <figure class="figure">
              <img src="@/assets/list.png" class="figure-img img-fluid rounded" alt="..." />
              <figcaption class="text-start start-title">紫竹高新区管理委员会召开2024年第一次会议</figcaption>
              <p class="text-start start-view">3月14日中午，紫竹高新区管理委员会2024年第一次会议在高新区本部大楼举行。</p>
              <p class="text-start text-time">2024 - 03 - 16</p>
            </figure>
          </div>
          <div class="col-md-4" @click="goToPage('PressCenterDetails')">
            <figure class="figure">
              <img src="@/assets/list.png" class="figure-img img-fluid rounded" alt="..." />
              <figcaption class="text-start start-title">紫竹高新区管理委员会召开2024年第一次会议</figcaption>
              <p class="text-start start-view">3月14日中午，紫竹高新区管理委员会2024年第一次会议在高新区本部大楼举行。</p>
              <p class="text-start text-time">2024 - 03 - 16</p>
            </figure>
          </div> -->
            </div>
        </div>
    </div>
    <div class="containerBac">
        <div class="container" style="padding: 50px 0;">
            <div class="PcList" v-for="(data, index) in DataView" :key="index"  @click="handleClick(index)">
                <div class="DataList">
                    <div class="DataList-top">
                        <div class="DataList-title">
                            {{ currentLanguage === 'zh' ? data.title_cn : data.title_en }}
                        </div>
                        <div class="DataList-more hover-div" :disabled="loading">
                            了解详情
                        </div>
                    </div>
                    <div class="DataList-time">{{data.release_time}}</div>
                    <div class="DataList-View">
                        {{ currentLanguage === 'zh' ? data.description_cn : data.description_en }}
                    </div>
                </div>
                <div class="lineadd"></div>
                <div class="DataTag"></div>
            </div>
            <div class="moreAll">
                <button class=" aboutBtn" v-if="!noMoreNews && !loading" @click="loadMore" :disabled="loading">更多新闻</button>
            </div>
        </div>
    </div>
    <FooterBar />

    <!-- 加载中提示 -->
    <div v-if="loading">加载中...</div>

    <!-- 全部新闻已加载完毕提示 -->
    <div v-if="noMoreNews">新闻已加载完毕</div>
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
    name: 'PressCenter',
    components: {
        NavBar,
        FooterBar,
    },
    data() {
        return {
            activeTab: 'news', // 'home', 'about', 'news', 'contact', 'other'
            currentLanguage: this.$i18n.locale,
            loading: false,
            error: null,
            dataTop: '',
            data: [],
            pageIndex: 0,
            pageSize: 10,
            DataView: [],
            noMoreNews: false, // 是否已加载完所有新闻
        };
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    created() {
        this.fetchData();
        this.fetchTabsData();
    },
    methods: {
        goToPage(page) {
            console.log(page);
            window.open("/" + page);
        },
        async fetchData() {
            this.loading = true;
            try {
                const params = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    sort: 'DESC'
                };

                const response = await this.$api.get('/api/news/list', {
                    params
                });

                // 判断返回的数据是否为空
                if (response.data.row && response.data.row.length > 0) {
                    this.DataView = this.DataView.concat(response.data.row); // 将新数据追加到现有数据中
                    this.pageIndex += 1; // 增加分页索引，以便加载下一页数据
                } else {
                    this.noMoreNews = true;
                    // alert('新闻已加载完毕');
                }
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        async fetchTabsData() {
            this.loading = true;
            try {
                const response = await this.$api.get('/api/news/top', {

                });
                console.log(response.data)
                this.dataTop = response.data;
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        loadMore() {
            // this.pageIndex += 1;
            this.fetchData();
        },
        handleClick(index) {
            console.log(this.DataView[index])
            if (this.DataView[index].type == "wxPage") {
                console.log('wxPage')
                console.log(this.DataView[index].url_cn)
                window.open(this.DataView[index].url_cn, '_blank');
            }
            if (this.DataView[index].type == "video") {
                console.log('video')
                console.log(this.DataView[index].video_url_cn)
                window.open(this.DataView[index].video_url_cn, '_blank');
            }
            if (this.DataView[index].type == "html") {
                let tid = this.DataView[index].n_id;
                console.log(tid);
                let routeData = this.$router.resolve({
                    name: 'PressCenterDetails',
                    query: {
                        tid: tid
                    }
                });
                window.open(routeData.href, '_blank');
            }
        },
        handleClickTop(index) {
            console.log(this.dataTop[index])
            if (this.dataTop[index].type == "wxPage") {
                console.log('wxPage')
                console.log(this.dataTop[index].url_cn)
                window.open(this.dataTop[index].url_cn, '_blank');
            }
            if (this.dataTop[index].type == "video") {
                console.log('video')
                console.log(this.dataTop[index].video_url_cn)
                window.open(this.dataTop[index].video_url_cn, '_blank');
            }
            if (this.dataTop[index].type == "html") {
                let tid = this.dataTop[index].n_id;
                console.log(tid);
                let routeData = this.$router.resolve({
                    name: 'PressCenterDetails',
                    query: {
                        tid: tid
                    }
                });
                window.open(routeData.href, '_blank');
            }
        }
    }
};
</script>

<style scoped>
.about-bac {
    position: relative;
    background: url(@/assets/PressCenter/PressCenter-1.png) 0px 0px / contain no-repeat fixed;
    height: 260px;
}
.start-view {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em; 
  max-height: 5.5em; 
  min-height: 5.5em;
   font-size: 16px;
    padding-top: 20px;
}

.PcList {
    position: relative;
}
.start-view{
        text-align: justify;
}
.breadCrumb-a {
    padding: 25px 0 0px;
    border-bottom: 1px solid #8291C1;
}
.start-viewpad {
    padding: 20px;
}
.start-viewpadb {
    padding:10px 20px;
}
.lineadd {
    margin: 0 30px;
    width: 100%;
    border-top: 1px dashed #8291C1;
}

.DataList {
    border-left: 1px solid #5266A5;
    padding: 50px 0 50px 30px;

}

.DataList-title {
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
}

.DataList-more {
    width: 88px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    opacity: 1;
    line-height: 30px;
    background: #FFFFFF;
    color: #619F90;
    box-sizing: border-box;
    border: 1px solid #619F90;
}

.DataList-time {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: #5266A5;
    padding: 10px 0;
}

.DataListView {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D;
    padding-bottom: 30px;
    border-bottom: 1px dashed #8291C1;
}

.DataList-top {
    display: flex;
    justify-content: space-between;
}

.DataTag {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #F5F5F5;
    border: 3px solid #5266A5;
    position: absolute;
    left: -6px;
    top: 50%;
}

.containerBac {
    background-color: #F5F5F5;
}

.LoadMore {
    width: 218px;
    height: 64px;
    border-radius: 33px;
    opacity: 1;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #5266A5;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #5266A5;
}

.moreAll {
    text-align: center;
    padding: 30px 0;
}

.listimg {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.DataList-more:hover {
    background-color: #619F90;
    color: #fff;
}
</style>
