<template>
<div class="footer">
    <div class="container footer-all">
        <div class="row">
            <div class="col-4">
                <div class="footer-title hover-div" v-if="currentLanguage !== 'en'">
                    <router-link to="/PressCenter">新闻中心</router-link>
                </div>
                <div class="footer-title hover-div">{{ $t('contact') }}</div>
                <div class="footer-view">
                    <img class="footer-site" src="@/assets/icon-site.png" alt="地址" />
                    {{ $t('dizhi') }}
                </div>
                <div class="footer-view">
                    <img class="footer-tel" src="@/assets/icon-tel.png" alt="地址" />
                    021-54161688
                </div>
            </div>
            <div class="col-2">
                <div class="footer-title hover-div">
                    <router-link to="/about">{{ $t('guanyu') }}</router-link>
                </div>
                <div class="footer-view hover-div" @click="goToPage('about')">{{ $t('jianjie') }}</div>
                <div class="footer-view hover-div" @click="goToPage('about')">{{ $t('jiazhi') }}</div>
                <div class="footer-view hover-div" @click="goToPage('about')">{{ $t('licheng') }}</div>
                <!-- <div class="footer-view hover-div" @click="goToPage('about')">{{ $t('rencai') }}</div> -->
                <div class="footer-view hover-div" @click="goToPage('ESG')">{{ $t('shehui') }}</div>
                <!-- <div class="footer-view hover-div">{{ $t('dang') }}</div> -->
            </div>
            <div class="col-2">
                <div class="footer-title hover-div">
                    <router-link to="/AppLayout">{{ $t('zhizhao') }}</router-link>
                </div>
                <div class="footer-view hover-div" @click="goToPage('AppLayout')">{{ $t('baozhuang') }}</div>
                <div class="footer-view hover-div" @click="goToPage('AppLayout')">{{ $t('ruanbao') }}</div>
                <div class="footer-view hover-div" @click="goToPage('AppLayout')">{{ $t('qita') }}</div>
            </div>
            <div class="col-2">
                <div class="footer-title hover-div"><router-link to="/PageLayout">{{ $t('qitalei') }}</router-link>
                </div>
                <div class="footer-view hover-div" @click="goToPage('PageLayout')">{{ $t('yanfa') }}</div>
                <div class="footer-view hover-div" @click="goToPage('PageLayout')">{{ $t('daxue') }}</div>
                <div class="footer-view hover-div" @click="goToPage('PageLayout')">{{ $t('peitao') }}</div>
                <div class="footer-view hover-div" @click="goToPage('PageLayout')">{{ $t('chuangxin') }}</div>

            </div>
            <div class="col-2">
                <div class="footer-title hover-div">
                    <router-link to="/realEstate">{{ $t('dichanye') }}</router-link>
                </div>
                <div class="footer-view hover-div" @click="goToPage('realEstate')">{{ $t('zhuzai') }}</div>
                <div class="footer-view hover-div" @click="goToPage('realEstate')">{{ $t('jiudian') }}</div>
                <div class="footer-view hover-div" @click="goToPage('realEstate')">{{ $t('bangong') }}</div>
            </div>
        </div>
    </div>
    <div class="icp">{{ $t('icp') }}<img src="@/assets/icp.png" alt="icp" /></div>
</div>
</template>

<script>
export default {
    name: 'FooterBar',
    data() {
        return {
            currentLanguage: this.$i18n.locale
        };
    },
    watch: {
        '$i18n.locale'(newLocale) {
            this.currentLanguage = newLocale;
        },

    },
    methods: {
        goToPage(page) {
            console.log(page);
            window.open("/" + page);
        },
    }
};
</script>

<style scoped>
.footer {
    background-color: #333333;
}

.footer-title a {
    color: #8291C1;
}

.footer-title {
    font-size: 24px;
    color: #8291C1;
    padding-bottom: 18px;
}

.footer-site {
    width: 18px;
    height: 21.73px;
    margin-right: 15px;
}

.footer-tel {
    width: 18px;
    height: 18px;
    margin-right: 15px;
}

.footer-view {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    color: #ffffff;
    padding-bottom: 16px;
}

.footer-view a {
    color: #ffffff;
}

.footer-all {
    padding: 56px 18px;
}

.icp {
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    padding: 40px 0 30px;
    background-color: #000000;
}

.icp img {
    width: 31px;
    height: 32px;
    margin-left: 20px;
}

a {
    text-decoration: none;
}
</style>
