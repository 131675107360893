import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'; // 引入 Bootstrap 的 JavaScript
import i18n from './i18n';
import api from './api/api';

const imgUrl = 'http://zjdev.dzhcn.top/assets/';

Vue.config.productionTip = false;
Vue.use(api);
router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '紫江集团官网';  
  }
  next();
});
Vue.directive('intersect', {
  bind(el, binding) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // 添加动画类
          el.classList.add(...binding.value.split(' '));
          observer.unobserve(el); // 动画触发后停止观察
        }
      });
    });

    observer.observe(el);
  }
});


Vue.prototype.$imgUrl = imgUrl;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
