// src/plugins/http.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://zjdev.dzhcn.top', 
  timeout: 10000, // 请求超时时间
});

// 添加请求拦截器
api.interceptors.request.use(config => {
  // 在发送请求之前做些什么，比如添加token
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
api.interceptors.response.use(response => {
  // 对响应数据做点什么
  return response.data;
}, error => {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default {
  install(Vue) {
    Vue.prototype.$api = api;
  }
};
