<!-- src/views/ContactUs.vue -->
<template>
    <div>

        <div class="container-fluid about-bac">
            <NavBar :activeTab="activeTab" />
            <!-- <img src="../assets/about/image@2x.png" alt="关于紫江" /> -->
        </div>
        <div class="breadCrumb-all">
            <div class="container breadCrumb-a">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item hover-div" @click="goToPage('')">{{ $t('home') }}</li>
                        <li class="breadcrumb-item" aria-current="page">{{ $t('contact') }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="container ContactUs-all">
            <div class="row">
                <div class="col-md-4">
                    <div class="ContactUs">
                        <div class="ContactUs-left">
                            <div class="ContactUs-contact">
                                <div class="ContactUs-border ">{{ $t('contact') }}</div>
                            </div>
                            <div class="ContactUs-site ContactUs-text">
                                <img class="footer-site" src="@/assets/me/dizhi.png" alt="地址" />
                                {{ $t('dizhi') }}
                            </div>
                            <div class="ContactUs-call ContactUs-text">
                                <img class="footer-site" src="@/assets/me/call.png" alt="" />
                                021-54161688
                            </div>
                            <div class="ContactUs-em ContactUs-text">
                                <img class="footer-site" src="@/assets/me/em.png" alt="" />
                                201199
                            </div>
                            <div class="ContactUs-qr">
                                <img src="@/assets/me/qr.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-8">
                    <div class="ContactUsRight">
                        <div class="ContactUs-left">
                            <div class="ContactUs-contact">
                                <div class="ContactUs-border ">{{ $t('contact') }}</div>
                                <div class="ContactUs-view">您可以在这里发送有关我们的公司或品牌的任何意见、建议或问题。</div>
                                <form @submit.prevent="handleSubmit">
                                    <div class="form-group">
                                        <input type="text" id="name" v-model="form.name" :placeholder="$t('name')"
                                            required class="form-control" :class="{ 'is-invalid': errors.name }" />
                                        <div v-if="errors.name" class="invalid-feedback">
                                            {{ errors.name }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" id="email" v-model="form.email"
                                            :placeholder="$t('email')" required class="form-control"
                                            :class="{ 'is-invalid': errors.email }" />
                                        <div v-if="errors.email" class="invalid-feedback">
                                            {{ errors.email }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="tel" id="mobile" v-model="form.mobile"
                                            :placeholder="$t('mobile')" required class="form-control"
                                            :class="{ 'is-invalid': errors.mobile }" />
                                        <div v-if="errors.mobile" class="invalid-feedback">
                                            {{ errors.mobile }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <textarea id="content" v-model="form.content" :placeholder="$t('content')"
                                            required class="form-controlAdd"
                                            :class="{ 'is-invalid': errors.content }"></textarea>
                                        <div v-if="errors.content" class="invalid-feedback">
                                            {{ errors.content }}
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary">提交</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <FooterBar />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';
export default {
    name: 'ContactUs',
    components: {
        NavBar,
        FooterBar,
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                mobile: '',
                content: ''
            },
            errors: {}, // 用于存储验证错误信息
            activeTab: 'contact',
            currentLanguage: this.$i18n.locale
            //'home', 'about', 'news', 'contact', 'other'
        };
    },
    watch: {
        '$i18n.locale'(newLocale) {
            this.currentLanguage = newLocale;
        },

    },
    methods: {
         goToPage(page) {
            console.log(page);
            window.open("/" + page);
        },
        async handleSubmit() {
            // const params = {
            //     type: typeInt 
            // };
            // const params = this.form
            try {
                const response = await this.$api.post('/api/contactUs', {
                    name: this.form.name,
                    email: this.form.email,
                    mobile: this.form.mobile,
                    content: this.form.content
                });
                if (response) {
                    console.log("123")
                }
                // this.DataView = response.data.row[0];
                // console.log(this.DataView)
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                // 可以在这里添加任何需要在请求完成后执行的逻辑
            }
        }
    }
};
</script>

<style scoped>
.about-bac {
    position: relative;
    background: url(@/assets/me/image@2x.png) 0px 0px / contain no-repeat fixed;
    height: 260px;
}

.ContactUs-contact {
    padding: 63px 0 30px;
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #3D3D3D;

}

.ContactUs-border {
    border-left: 6px solid #8291C1;
    padding-left: 15px;
}

.ContactUs-site img {
    width: 16px;
    height: 22px;
    vertical-align: sub;
    margin-right: 10px;
}

.ContactUs-call img {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    margin-right: 10px;
}

.ContactUs-em img {
    vertical-align: sub;
    height: 18px;
    margin-right: 10px;
}

.ContactUs-text {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D;
    padding-bottom: 17px;
}

.ContactUs-qr {
    text-align: left;
}

.ContactUs-qr img {

    width: 80%;
    opacity: 1;

}

.ContactUs {
    background: #F5F5F5;
    padding: 30px;
}

.breadCrumb-a {
    padding: 25px 0 0px;
    border-bottom: 1px solid #8291C1;
}

.ContactUs-all {
    padding-top: 30px;
}

.ContactUs-view {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D;
}

.ContactUs-view {
    padding: 30px 0;
}

.form-control {
    border-radius: 5px;
    opacity: 1;

    background: #FFFFFF;
    width: 100%;
    height: 75px;
    font-size: 16px;
    font-weight: 500;
}

.form-controlAdd {
    border-radius: 5px;
    opacity: 1;

    background: #FFFFFF;
    width: 100%;
    height: 160px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
}

.form-group {
    padding-bottom: 30px;
}

.btn {
    width: 218px;
    height: 64px;
    border-radius: 33px;
    opacity: 1;

    background: #FFFFFF;
    color: #5266A5;
    box-sizing: border-box;
    border: 1px solid #5266A5;
    font-size: 16px;
    font-weight: 500;
}

.ContactUsRight {
    padding: 30px;
}
</style>
